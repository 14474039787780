<template>
  <div>
    <div class="navSlo">
      <div class="navSlo-left">携手共创丨拥抱全球化机遇</div>
      <div class="navSlo-right">
        <div class="f14px1E2">
          <!--          <img-->
          <!--            src="@/assets/gb.svg"-->
          <!--            style="width: 18px; height: 14px; margin-right: 10px"-->
          <!--          />-->
          <!--          <span @click="changeLanguage(0)">EN</span>&ensp;|&ensp;-->
          <img
            src="@/assets/cn.svg"
            style="width: 18px; height: 14px; margin-right: 10px"
          />
          <span class="on" @click="changeLanguage(1)">CN</span>
        </div>
        <div class="navSlo-right-cont" @click="showModal = true">
          <i></i><span class="f14pxBA9153">{{ $t("contactus") }}</span>
        </div>
      </div>
    </div>
    <div class="navLogo">
      <template v-for="(nav, key) in navList">
        <router-link v-if="nav.id == 3" to="/" class="logo"
          ><img src="@/assets/logo.svg" alt=""
        /></router-link>
        <router-link
          v-else-if="nav.id == 2"
          :to="`${nav.link}`"
          class="f18pxfff nav_more"
          style="line-height: 18px"
        >
          {{ nav.name }}
          <ul class="nav_project">
            <li><router-link to="/ireland">爱尔兰</router-link></li>
            <li><router-link to="/vanuatu">瓦努阿图</router-link></li>
            <li>
              <router-link to="/japan"
                ><div></div>
                日本</router-link
              >
            </li>
            <li>
              <router-link to="/nauru"
                ><div></div>
                瑙鲁</router-link
              >
            </li>
          </ul>
        </router-link>
        <router-link
          v-else
          :to="`${nav.link}`"
          class="f18pxfff on"
          style="line-height: 18px"
          >{{ nav.name }}
          <!-- <div class="select" v-if="nav.id == 2">
            <router-link to="ireland"><i></i>爱尔兰</router-link>
            <router-link to="vanuatu"><i></i>瓦努阿图</router-link>
          </div> -->
        </router-link>
      </template>
      <!--      <router-link to="/" class="f14pxfff on">首页</router-link>-->
      <!--      <router-link to="/project" class="f14pxfff">服务项目</router-link>-->
      <!--      <router-link to="/" class="logo"-->
      <!--        ><img src="@/assets/logo.png" alt=""-->
      <!--      /></router-link>-->
      <!--      <router-link to="/news" class="f14pxfff">新闻资讯</router-link>-->
      <!--      <router-link to="/aboutus" class="f14pxfff">关于我们</router-link>-->
    </div>
    <Dialog :visible.sync="showModal"></Dialog>
  </div>
</template>
<script>
import "@/style/common.css";
import Dialog from "@/components/Dialog.vue";
import $ from "jquery";
import { GetNav } from "@/api/api";
export default {
  name: "Navbar",
  data() {
    return {
      showModal: false,
      navList: [],
    };
  },
  components: {
    Dialog,
  },
  created() {
    GetNav().then((res) => {
      if (res.data.code == 1) {
        this.navList = res.data.data;
      }
    });
  },
  methods: {
    changeLanguage(_index) {
      // $(".navSlo-right .f14px1E2>span:eq('" + _index + "')")
      //   .addClass("on")
      //   .siblings("span")
      //   .removeClass("on");
      const lang = _index == 0 ? "en-US" : "zh-CN";
      this.$i18n.locale = lang;
      localStorage.setItem("language", lang);
      location.reload();
    },
  },
  mounted() {
    // 监听事件
    window.addEventListener("scroll", function () {
      let t = $("body, html").scrollTop(); // 目前监听的是整个body的滚动条距离
      if (t > 0) {
        $(".navLogo").addClass("navbar-active");
      } else {
        $(".navLogo").removeClass("navbar-active");
      }
    });
    $(function (options) {
      // if (localStorage.getItem("language") == "zh-CN") {
      //   $(".navSlo-right div span:eq(1)")
      //     .addClass("on")
      //     .siblings("span")
      //     .removeClass("on");
      // } else {
      //   $(".navSlo-right div span:eq(0)")
      //     .addClass("on")
      //     .siblings("span")
      //     .removeClass("on");
      // }
      $(".navLogo .f14pxfff").click(function (e) {
        // $(this).addClass("on").siblings(".f14pxfff").removeClass("on");
      });
    });
  },
};
</script>
<style scoped>
.navSlo {
  width: 100%;
  height: 40px;
  background: #f7f7f7;
  display: flex;
  justify-content: space-between;
  padding: 0 8.33vw;
}
.navSlo-left {
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  color: #405161;
}
.navSlo-right,
.navSlo-right .f14px1E2,
.navSlo-right .navSlo-right-cont {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.navSlo-right > .f14px1E2 {
  color: #999999;
}
.navSlo-right .navSlo-right-cont i {
  display: inline-block;
  /* background: #383a49; */
  background: url(@/assets/icon-earth3.png) no-repeat center;
  background-size: cover;
  width: 18px;
  height: 18px;
  margin-left: 43px;
  margin-right: 10px;
}
.navSlo-right .f14px1E2 span.on {
  color: #405161;
}
.navLogo {
  width: 100%;
  height: 80px;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
  /* padding: 0 32.55%; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1001;
}
.navLogo > a {
  margin-right: 80px;
}
.navLogo > a:last-of-type {
  margin-right: 0px;
}

.navLogo .f18pxfff {
  color: #000;
  height: 80px;
  line-height: 80px !important;
  position: relative;
}
.navLogo .f18pxfff:hover {
  color: #405161 !important;
  border-bottom: 1px solid #405161;
}
.navLogo .logo {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navLogo .logo img {
  width: 148px;
  height: 60px;
  object-fit: cover;
}
.navbar-active {
  position: fixed;
  top: 0;
  left: 0;
}

.navLogo .f18pxfff .select {
  display: none;
  /* display: block; */
  background: #fff;
  position: absolute;
  min-width: 136px;
  box-sizing: border-box;
  left: -20px;
  font-size: 14px;
  color: #000;
  line-height: 14px;
  padding: 10px 0;
  font-weight: 400;
}
.navLogo .f18pxfff .select > a {
  display: block;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}
.navLogo .f18pxfff .select > a i {
  display: inline-block;
  width: 30px;
  height: 15px;
  margin-right: 10px;
}
.navLogo .f18pxfff .select > a:first-of-type i {
  background: url(@/assets/project-ireland1.png) no-repeat;
  background-size: cover;
}
/* .navLogo .f18pxfff .select > a:nth-child() i {
  background: url(@/assets/project-vanuatu1.png) no-repeat;
  background-size: cover;
} */
.navLogo .f18pxfff .select > a:last-of-type i {
  background: url(@/assets/project-vanuatu1.png) no-repeat;
  background-size: cover;
}
.navLogo .f18pxfff:hover .select {
  display: block;
  color: #405161;
}
.nav_more {
  position: relative;
}
.nav_more:hover .nav_project {
  display: block;
}

.nav_project {
  position: absolute;
  z-index: 900;
  top: 80px;
  left: -20px;
  display: none;
  min-width: 115px;
  background: #ffffff;
  padding: 20px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}
.nav_project li {
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  line-height: 18px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  list-style: none;
  padding-left: 40px;
  margin-bottom: 20px;
  background: url(@/assets/icon-i.png) no-repeat left center;
  background-size: 30px 18px;
}
.nav_project li:nth-child(2) {
  background: url(@/assets/icon-v.png) no-repeat left center;
  background-size: 30px 18px;
}
.nav_project li:nth-child(3) {
  background: url(@/assets/icon-j.png) no-repeat left center;
  background-size: 30px 18px;
}
.nav_project li:last-of-type {
  margin-bottom: 0;
  background: url(@/assets/icon-n.png) no-repeat left center;
  background-size: 30px 18px;
}
.nav_project li:hover {
  color: #405161;
}
.nav_project li:last-of-type {
  position: relative;
  z-index: 902;
}
.nav_project li:last-of-type div {
  width: 30px;
  height: 18px;
  position: absolute;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  z-index: 901;
  left: 0;
  top: 0;
}
</style>
