export default {
  // 首页
  aboutus: "ABOUT US",
  contactus:"CONTACT US",
  downpdf:"DOWNLOAD",
  getData:"ACQUIRING DATA",
  news: "NEWS",
  address:"2903A, Building A, Beijing IFC, Chaoyang District, Beijing",
  messageBord:"MESSAGE BORD",
  username:"Name",
  phone:"Phone",
  email:"Email",
  message:"Your Message",
  submit:"SUBMIT",
  pleaseLeave:"Please leave your contact information",
  pleaseCorrect:"Please provide the correct email address for us to contact you",
  submitSuccess:"SUBMIT SUCCESSFULLY",
  bottom:"2024 FUTURE CITIZEN BUREAU. All Rights Reserved.",
  newsPrev:"PREVIOUS POST",
  newsNext:"NEXT CHAPTER",
  newsReturnList:"RETURN LIST",
  LearnMore:"LEARN MORE",
  showDetail:"Detail",
  irelandProject:"IRELAND PROJECT",
  vanuatuProject:"VANUATU PROJECT",
  index_banner_ireland1:"The Irish Joint Investment Immigration Program aims to attract global investors by injecting capital into Irish companies and participating in their strategic operations and management as executives.",
  index_banner_ireland2:"Bill basis: The combination of the DETE (Irish Department of Labour) Critical Skills Employment Permits Act and the Irish Immigration Stamp 1 Act enables investment applicants to obtain a residence visa based on a senior business manager, allowing them to smoothly transition to Irish long-term residency status after two years.",
  index_banner_vanuatu1:"Vanuatu Citizenship Immigration: According to the Immigration Scheme Act for Investment/Donation Contributions, investment applicants are eligible to submit a citizenship application under Section 13 of the Citizenship Act, Chapter 112, by investing in or directly donating contributions to projects designated and approved by the Vanuatu government. Upon successful application, they will obtain Vanuatu citizenship.",
  index_banner_vanuatu2:"The Republic of Vanuatu, in accordance with Article 13 of Chapter 112 of the Citizenship Act, has successively passed the Nationality Regulations (Donation Program) Act No. 220 of 2016, also known as the Development Support Program (DSP), and the Citizenship Capital Investment Immigration Program Act No. 8 of 2023 and the Citizenship Capital Investment Immigration Program Act No. 58 of 2023, also known as the Capital Investment Immigration Plan (CIIP).",
  service_content:"SERVICE CONTENT",
  cooperateAdv:"COOPERATIVE ADVANTAGES",
  bahamas:"BAHAMAS",
  slovenia:"SLOVENIA",
  ireland:"IRELAND",
  vanuatu:"VANUATU",
  projectPlan:"PROJECT PLAN",
  projectOverview:"PROJECT OVERVIEW",
  applicant:"APPLICATION REQUIREMENTS",
  moreNews:"MORE NEWS",
  flag:"NATIONAL FLAG",
  emblem:"NATIONAL EMBLEM",
};
